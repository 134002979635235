/*eslint no-restricted-globals: 0*/
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { getConfig } from 'tw-oi-core'
import { filterTopicsByField, sortItemsByTitle } from 'tw-oi-core/utils/data'
import {
  trackSearchResultsTopicClick,
  trackSearchKeyword,
  trackEmptyResultsSearchKeyword,
  trackPublicationClick,
  trackRecentlyViewedTopicClick
} from 'tw-oi-core/services/analytics'

import {
  ROUTE,
  MESSAGE
} from '../config'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as ContentsActions from 'tw-oi-core/actions/ContentsActions'
import ScreenContainer from '../components/ScreenContainer'
import TopicsList from '../components/TopicsList'
import TopicsListSearch from '../components/TopicsListSearch'
import Loader from '../components/Loader'
import SearchInput from '../components/SearchInput'
import ErrorMessage from '../components/ErrorMessage'
import BodyClass from '../components/BodyClass'

import '../styles/Topics.scss'

class Search extends Component {

  static propTypes = {
    fetching: PropTypes.bool,
    errorSearch: PropTypes.object,
    topics: ImmutablePropTypes.list,
    publications: ImmutablePropTypes.list,
    recent: ImmutablePropTypes.list,
    currentYear: PropTypes.string,
    currentModel: PropTypes.string,
    currentBrand: PropTypes.string,
    ContentsActions: PropTypes.shape({
      setSearchQuery: PropTypes.func.isRequired,
      getContents: PropTypes.func.isRequired
    }).isRequired,
    match: PropTypes.object.isRequired,
    baseRoute: PropTypes.string.isRequired,
    searchQuery: PropTypes.string,
    searchTopics: ImmutablePropTypes.list,
    searchContentsUpdated: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isDesktop: PropTypes.bool,
    isLandscape: PropTypes.bool,
    history: PropTypes.object,
  }

  constructor(props) {
    super(props)

    this.state = {
      sortDescend: false,
    }
  }

  shouldComponentUpdate(nextProps) {
    // do not hide loader until new content will arrive
    return !(!nextProps.errorSearch && !nextProps.fetching && this.props.fetching && nextProps.searchContentsUpdated === this.props.searchContentsUpdated)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.searchQuery !== this.props.searchQuery) {
      if (this.props.searchQuery) {
        this.getSearchContents(this.props.searchQuery)
      }
    }

    if (prevProps.searchContentsUpdated !== this.props.searchContentsUpdated) {
      this.trackAnalyticsEvents()
    }
  }

  getSearchContents = (searchQuery) => {
    const { DEFAULT_LOCALE } = getConfig()
    const { currentBrand, currentYear, currentModel, ContentsActions } = this.props

    ContentsActions.getContents(currentBrand, currentYear, currentModel, DEFAULT_LOCALE, searchQuery)
  }

  /**
   * Captures analytics search events
   */
  trackAnalyticsEvents() {
    const { searchQuery, searchTopics } = this.props

    if (!searchQuery) {
      return
    }

    // track empty search results and return early
    if (searchTopics && searchTopics.size === 0) {
      trackEmptyResultsSearchKeyword(searchQuery)
    }

    // track search keyword
    trackSearchKeyword(searchQuery)
  }

  trackItemClick(item) {
    switch (item.type) {
    case 'topic':
      trackSearchResultsTopicClick(item.title)
      break
    case 'publication':
      trackPublicationClick(item.title)
      break
    default:
        // Do nothing in case of unsupported content type
    }
  }

  renderTopics = () => {
    const { topics, recent, searchQuery, errorSearch, fetching, baseRoute } = this.props

    if (!searchQuery) {
      const recentlyViewed = sortItemsByTitle(filterTopicsByField(topics, recent ? recent.toJS() : [], 'resourceKey', true))

      return (
        <TopicsList
          topics={recentlyViewed}
          onClick={(topic) => trackRecentlyViewedTopicClick(topic.get('title'))}
          emptyText={MESSAGE.EMPTY_RECENT}
          baseRoute={baseRoute + ROUTE.SEARCH}
        />
      )
    }

    if (errorSearch) {
      return <ErrorMessage className="inverse inline" retryAction={() => this.getSearchContents(this.props.searchQuery)} />
    }

    if (searchQuery) {
      if (fetching) {
        return (
          <Loader className="inverse" />
        )
      } else {
        return (
          <TopicsListSearch
            searchQuery={searchQuery}
            baseRoute={`${baseRoute}${ROUTE.SEARCH}`}
            onClick={topic => this.trackItemClick(topic)}
          />
        )
      }
    }
  }

  handleGoBack = () => {
    this.props.history.replace(`${this.props.baseRoute}${ROUTE.EXPLORE}`)
  }

  render() {
    const { match, searchQuery, ContentsActions, errorSearch, fetching, isLandscape } = this.props

    return (
      <div className="Topics one">
        <div className="screen-content">
          <ScreenContainer currentUrl={match.url}>
            <div className="topics">
              <BodyClass className="collapsed-search" condition={isLandscape} />
              <SearchInput
                autoFocus
                searchQuery={searchQuery}
                onChange={ContentsActions.setSearchQuery}
                onGoBack={this.handleGoBack}
              />
              {(!searchQuery || (!fetching && !errorSearch)) &&
                <div className="topics-subtitle"> {searchQuery ? "Search Results" : "Recently Viewed"}</div>
              }
              {this.renderTopics()}
            </div>
          </ScreenContainer>
        </div>
      </div>
    )
  }
}


function mapStateToProps({ contents, vehicle, user: { recent, media: { isLandscape, isDesktop } } }) {
  const {
    topics,
    fetching,
    currentAsset,
    fetchingAsset,
    searchQuery,
    searchTopics,
    errorSearch,
    searchContentsUpdated,
    publications
  } = contents
  const { currentYear, currentModel, currentBrand } = vehicle

  recent = recent.getIn([currentYear, currentModel])

  return {
    topics,
    recent,
    fetching,
    currentAsset,
    fetchingAsset,
    currentYear,
    currentModel,
    currentBrand,
    searchQuery,
    searchTopics,
    searchContentsUpdated,
    errorSearch,
    isDesktop,
    isLandscape,
    publications
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ContentsActions: bindActionCreators(ContentsActions, dispatch),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Search)
