const WARNING_LIGHTS_ROOT_TOPIC_TITLES = ['icon glossary', 'warning & indicator lights']

export function getRootTopicForWarningLightsPublication(topics) {
  return topics.find(topic => isWarningLightsRootTopic(topic.get('title')))
}

/**
 * @param {string} title
 * @returns {boolean}
 */
export function isWarningLightsRootTopic(title) {
  return WARNING_LIGHTS_ROOT_TOPIC_TITLES.includes(title.toLowerCase())
}
