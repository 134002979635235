import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { getConfig } from 'tw-oi-core'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import classNames from 'classnames'

import { ROUTE, MESSAGE } from '../config'

import Media from './Media'
import Icon from './Icon'
import './ScreenHead.scss'

export const SCREEN_HEAD_VARIANT = {
  DEFAULT: 'DEFAULT',
  SEARCH: 'SEARCH',
}

class ScreenHead extends Component {
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.any,
    back: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    backTitle: PropTypes.string,
    location: PropTypes.object.isRequired,
    openedModals: PropTypes.array,
    landscapeOverlay: PropTypes.bool,
    userMedia: PropTypes.shape({
      isDesktop: PropTypes.bool,
      isLandscape: PropTypes.bool,
    }),
    withSearch: PropTypes.bool,
    baseRoute: PropTypes.string,
    variant: PropTypes.oneOf(Object.keys(SCREEN_HEAD_VARIANT)),
    history: PropTypes.object.isRequired,
  }
  static defaultProps = {
    title: '',
    back: null,
    backTitle: "Back",
    landscapeOverlay: true,
    variant: SCREEN_HEAD_VARIANT.DEFAULT,
  }

  terms = null

  render() {
    const { FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW } = getConfig()
    const { variant, back, backTitle, userMedia, landscapeOverlay, baseRoute, withSearch, history } = this.props

    let backLink = null

    if (back) {
      if (typeof back === 'function') {
        backLink = (
          <button onClick={back}>
            <Icon type="arrow-small" />
            {backTitle}
          </button>
        )
      } else {
        backLink = (
          <Link to={back} replace>
            <Icon type="arrow-small" />
            {backTitle}
          </Link>
        )
      }
    }

    const title = this.props.children || this.props.title
    const showRotationWarning = userMedia.isLandscape && landscapeOverlay

    return (
      <>
        <div className="screen-head">
          {showRotationWarning && (
            <div className="screen-overlay">
              <Icon type="rotate-device"/>
              <div className="overlay-text">{MESSAGE.ROTATE_DEVICE_ORIENTATION}</div>
            </div>
          )}

          {variant === SCREEN_HEAD_VARIANT.SEARCH && !back ? (
            <div className='ScreenHead' onClick={() => history.push(`${baseRoute}${ROUTE.SEARCH}`)}>
              <div className='ScreenHead__search'>
                <div className='ScreenHead__search-icon'>
                  <Icon type='search' />
                </div>
                <div className='ScreenHead__search-placeholder'>Search manuals & guides</div>
              </div>
            </div>
          ) : (
            <>
              <Media type="desktop">
                <>
                  <Link to={ROUTE.INDEX} className="screen-logo"/>
                  <h1 className="screen-title">{title}</h1>
                </>
              </Media>

              <Media type="mobile">
                <>
                  {back ? <div className={classNames(
                    "screen-back",
                    backTitle.length > 25 && "title-long",
                  )}>{backLink}</div>  :
                    <div className="screen-title">{title}</div>}

                  {(withSearch && baseRoute) ? <Link className='search' to={`${baseRoute}${ROUTE.SEARCH}`}>
                    <Icon type="search"/>
                  </Link> : null}
                </>
              </Media>
            </>
          )}


          {FEATURE_ENABLED_DRAFT_CONTENT_PREVIEW && <div className='content-preview-mode'>Draft content preview</div>}

        </div>
      </>
    )
  }
}


function mapStateToProps({ user }) {
  return {
    userMedia: user.media,
  }
}

export default connect(mapStateToProps, null)(withRouter(ScreenHead))
