import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Icon from '../components/Icon'

export default class ImageMapSelector extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    active: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  static iconNames = {
    Exterior: {
      fileName: 'explore360',
      label: 'General',
    },
    Cargo: {
      fileName: 'exploreBack',
      label: 'Cargo',
    },
    FrontSeat: {
      fileName: 'exploreFront',
      label: 'Front',
    },
    WideCabin: {
      fileName: 'exploreSeat',
      label: 'Front seats',
    },
    RearSeat: {
      fileName: 'exploreSeat',
      label: 'Back seats',
    },
    Rear: {
      fileName: 'exploreBack',
      label: 'Rear',
    },
    DriverSeat: {
      fileName: 'exploreWheel',
      label: 'Driver seat',
    },
    Hatch: {
      fileName: 'exploreBack',
      label: 'Hatch',
    },
    TruckBed: {
      fileName: 'exploreBack',
      label: 'Truck bed',
    },
  }

  render() {
    const { items, active } = this.props

    return (
      <div className='image-map-selector'>
        {items.map(name => {
          return (
            <button
              key={name}
              className={classNames(name === active && 'active')}
              onClick={() => {
                this.props.onChange(name)
              }}
            >
              <Icon type={ImageMapSelector.iconNames[name].fileName} />
              <span className='button-name'>{ImageMapSelector.iconNames[name].label}</span>
            </button>
          )
        })}
      </div>
    )
  }
}
